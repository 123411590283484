<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row class="match-height">
          <transition
          :name="$store.state.appConfig.layout.routerTransition"
          mode="out-in"
          >
          <b-col md="6">
            <b-card v-if="Object.keys(category).length > 0">
              <b-form @submit.prevent="update">
                <b-row>

                  <!-- name -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Name')"
                      label-for="v-name"
                    >
                      <b-form-input
                        id="v-name"
                        placeholder="Name"
                        v-model="category.name"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- SEO Name -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Name (SEO)')"
                      label-for="v-seo-name"
                    >
                      <b-form-input
                        id="v-seo-name"
                        :placeholder="$t('Name (SEO)')"
                        v-model="category.seo_name"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- sort -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Sort')"
                      label-for="v-sort"
                    >
                      <b-form-input
                        id="v-sort"
                        type="tel"
                        placeholder="1"
                        v-model="category.sort"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- super_category_id -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Super Category')"
                      label-for="v-categories"
                    >
                      <v-select
                        v-model="category.super_category_id"
                        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="categories"
                      />
                      <b-form-text>{{$t('Select Super Category if this Category is Sub Category')}}</b-form-text>
                    </b-form-group>
                  </b-col>

                  <!-- Accept specific image formats by IANA type -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Image')"
                      label-for="v-image"
                    >
                      <b-img :src="category.media[0].url" v-if="category.media.length" width="120" thumbnail class="mb-1" />
                      <b-form-file
                        id="IANA"
                        accept="image/jpeg, image/jpg, image/png, image/gif"
                        :browse-text="$t('Browser')"
                        :placeholder="$t('Upload Image')"
                        v-model="category.new_image"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Is Active -->
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('Is Active ?')"
                      label-for="v-is-active"
                    >
                      <b-form-checkbox
                        id="v-is-active"
                        :checked="category.is_active == 1 ? true : false"
                        class="custom-control-primary"
                        @change="changeActive"
                        name="is_active"
                        switch
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <!-- Is Featured -->
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('Is Featured ?')"
                      label-for="v-is-featured"
                    >
                      <b-form-checkbox
                        id="v-is-featured"
                        :checked="category.is_featured == 1 ? true : false"
                        class="custom-control-primary"
                        @change="changeFeatured"
                        name="is_featured"
                        switch
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <!-- submit -->
                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      <template v-if="!isLoadingSave">
                        {{$t('Save')}}
                      </template>
                      <template v-if="isLoadingSave">
                        {{$t('Saveing')}}...
                        <b-spinner small />
                      </template>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </b-col>
          </transition>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  name: 'CategoriesEdit',

  data() {
    return {
      category: {},
      categories: [],
      isLoading: false,
      isLoadingSave: false,
    }
  },
  mounted() {
    this.getCategory();
    this.getCategories();
  },
  methods: {
    getCategory(){
      this.isLoading = true
      useJwt.post('/categories/get-by-id',{id: this.$route.params.id})
      .then((response) => {
        // console.log(response.data)
        this.category = response.data.data
        if(this.category.super_category_id != null) {
            this.category.super_category_id = this.category.super_category.name
        }
        this.isLoading = false
      })
      .catch(response => {
        // console.log(response);
      });
    },
    update() {
      this.isLoadingSave = true;

      // create form data to upload images
      let form = new FormData(); 
      Object.entries(this.category).forEach(([key, value]) => {
        form.append(key, value);
      });

      useJwt.post('/categories/update',form)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
      })
      .catch(error => {
        // console.log(error.response.data);
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    },
    getCategories(){
      useJwt.get('/categories/get-all')
      .then((response) => {
         console.log(response.data)
        this.categories = response.data.data.data
      })
      .catch(response => {
        // console.log(response);
      });
    },
    changeFeatured(){
        if(this.category.is_featured==0) {
            this.category.is_featured=1
        } else {
            this.category.is_featured=0
        }
    },
    changeActive(){
        if(this.category.is_active==0) {
            this.category.is_active=1
        } else {
            this.category.is_active=0
        }
    }
  }
}
</script>
